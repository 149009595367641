<template>
  <section class="page-apartment">
    <div class="banner">
      <p class="hd">JWP STUDENT APARTMENT</p>
      <p class="title">
        <span>Students Apartment</span>
      </p>
      <p class="desc">
        <span
          >To provide students with future learning, life, situational living
          experience in social scene</span
        >
      </p>
    </div>
    <ul class="colleges">
      <li class="college">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/landmark/student-apartment/college-1.png"
        />
        <p>
          <span>University of</span>
          <span>Nicosia</span>
        </p>
      </li>
      <li class="college">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/landmark/student-apartment/college-2.png"
        />
        <p>
          <span>European University</span>
          <span>of Cyprus</span>
        </p>
      </li>
      <li class="college">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/landmark/student-apartment/college-3.png"
        />
        <p>
          <span>University of</span>
          <span>Cyprus</span>
        </p>
      </li>
    </ul>
    <ul class="infos">
      <li class="info">
        <p class="k">Building Overview</p>
        <p class="v">
          12 units per building, 5 buildings in total, 60 one-bedroom apartments
        </p>
      </li>
      <li class="info">
        <p class="k">Delivery Date</p>
        <p class="v">December 2019</p>
      </li>
      <li class="info">
        <p class="k">Operation Management</p>
        <p class="v">
          Operated and managed by Nicosia’s well-known student apartment
          operator, with more than 20 years of mature student apartment
          operation management experience. There are more than one hundred
          apartment buildings operating in Nicosia at the same time, and the
          average income for the investors is around 4%
        </p>
      </li>
    </ul>
    <div class="scroll-wrap">
      <ul class="boxs">
        <li class="box">
          <van-image
            width="223"
            height="125"
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/student-apartment/img-1.jpg"
          />
        </li>
        <li class="box">
          <van-image
            width="223"
            height="125"
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/student-apartment/img-2.jpg"
          />
        </li>
        <li class="box">
          <van-image
            width="223"
            height="125"
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/student-apartment/img-3.jpg"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "Students Apartment";
  }
};
</script>

<style lang="less" scoped>
.page-apartment {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//static.hoolihome.com/global-jwp/assets/m/landmark/student-apartment/banner.jpg")
      no-repeat center center / cover;
    .hd {
      padding-bottom: 83px;
      font-size: 22px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 30px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    }
    .title {
      padding-bottom: 16px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 22px 39px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      span {
        display: inline-block;
        line-height: 17px;
      }
    }
  }
  .colleges {
    display: flex;
    justify-content: space-between;
    padding: 23px 22px 40px;
    .college {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 40px;
      }
      p {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
        line-height: 17px;
      }
    }
  }
  .infos {
    padding: 0 22px;
    .info {
      padding-bottom: 20px;
      .k {
        font-size: 16px;
        font-weight: bold;
        color: rgba(38, 38, 38, 1);
        line-height: 22px;
      }
      .v {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
        line-height: 20px;
      }
    }
  }
  .scroll-wrap {
    width: 100vw;
    overflow-x: scroll;
    margin-bottom: 50px;
    .boxs {
      display: flex;
      padding-left: 22px;
      .box {
        flex-shrink: 0;
        padding-right: 14px;
        overflow-y: hidden;
        &:last-child {
          padding-right: 22px;
        }
        .van-image {
          overflow: hidden;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
